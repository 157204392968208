<template>
	<v-container>
		<v-row>
			<v-col cols="12" class="text-center">
				<h1>
					Individueel Ontwikkel Plan
				</h1>
				<v-divider></v-divider>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12">
				<p class="font-weight-regular">
					Het afleren van niet helpende gewoonten/gedragingen en het aanleren van nieuwe kost energie, veel energie. Het is dan ook gemakkelijker om te vervallen in oude gewoonten, daar hoef je niets voor te doen.
				</p>

				<p class="font-weight-regular">
					Dit is jouw Individueel Ontwikkel Plan
				</p>

				<p class="font-weight-regular">
					Waarin jij duidelijk maakt wat jij wilt ontwikkelen en waarom deze ontwikkeling nodig is.
					Dit Individueel Ontwikkel Plan gaat je meer inzicht geven in wat er gebeurt en hoe jij daar verandering in gaat aanbrengen.
				</p>

				<p class="font-weight-regular">
					Het plan bestaat uit 4 verschillende stappen, die jou helpen bij het vormen van jouw plan.
				</p>

				<p class="font-weight-regular">
					Als eerste ga je in de <span class="color-accent">Huidige Situatie</span> omschrijven waarom er een ontwikkeling nodig is. Wat
					zijn de motivatoren? Vervolgens ga je in de <span class="color-accent">Gewenste Situatie</span> beschrijven wat het jou
					oplevert wanneer je gaat ontwikkelen. Maar ook breng je persoonlijke drivers in kaart. In de
					<span class="color-accent">Belemmeringen</span> omschrijf je jouw persoonlijke belemmeringen en die van jouw omgeving. In
					<span class="color-accent">Acties en Hulpbronnen</span> leg je duidelijk vast wat je gaat doen, wat het van jou vraagt en wat
					je nodig hebt om jouw doel/ontwikkeling te bereiken.
				</p>
			</v-col>


			<v-col cols="12" class="text-center py-0">
				<v-btn color="accent" block @click="navigateToCurrentSituation">Start Huidige Situatie</v-btn>
			</v-col>
		</v-row>

	</v-container>
</template>

<script>

export default {
	name: "dashboardWiseIOPBase",
	methods: {
		navigateToCurrentSituation() {
			this.$router.push({name: 'dashboardWiseIOPCurrentSituation'})
		},
	}
};
</script>
